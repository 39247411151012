<template>
	<div>
		<hero title="Corporate Compliance Courses" subtitle="Specialized compliance training courses for individuals,
	teams and organizations." class="mb-6"></hero>
	<b-container class="mb-6">
		<b-row>
			<b-col md="7" class="mb-6">
				<div class="op-heading">ComplianceNet courses offer a comprehensive selection of online business compliance training programs, each designed to assist with compliance in one particular area of law where significant risks are likely to arise.</div>
			</b-col>
			<b-col md="5" class="tiny mb-6">
				<img src="/assets/img/ComplianceNet_Logo_White.svg" alt="" style="max-width:230px">
				<p class="font-italic">ComplianceNet is now on WiseTech Academy</p>
				<hr>
				<p>Do you need an effective compliance training solution for your company?</p>
				<b-button @click.prevent="gotoForm">Contact Us</b-button>
			</b-col>
		</b-row>
	</b-container>
	<sorted-courses>All Corporate Compliance courses</sorted-courses>
	<contact-form title="Contact Us" ref="form">Contact us now to learn how we can provide an effective compliance training solution for your organisation and to organise your trial enrolment.</contact-form>
	</div>
</template>

<script>
export default {
	name: 'Compliance',
	methods: {
		gotoForm(e) {
			// const el = 
			e.stopImmediatePropagation()
			const topOfElement = this.$refs.form.$el.offsetTop;
			window.scroll({ top: topOfElement, behavior: "smooth" });
			// .scrollIntoView({behaviour: 'smooth'})
			setTimeout(() => {
				document.getElementById('name').focus()
			}, 200);
		}
	}
}
</script>